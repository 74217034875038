@import "../../../styles/_chunk";

.blog-post-card {
  background-color: $blog-bg-inverse-color;
  margin-bottom: 2rem;
  border-radius: $border-radius;

  &.card-dark-theme {
    background-color: map-get($dark-theme, secondary-background-color);

    .blog-img-container::before {
      border-bottom: 8vw solid map-get($dark-theme, secondary-background-color);
    }

    .blog-card-title,
    .blog-card-intro-text {
      color: $text-white !important;
    }

    .blog-card-hr {
      border-top: 1px solid $grey-10;
    }

    &:hover {
      .blog-card-title {
        color: $primary-core !important;
      }
    }
  }

  a:hover {
    text-decoration: none;

    .blog-card-title {
      color: $primary-core !important;
    }
  }

  .blog-img-container {
    position: relative;
    overflow: hidden;

    &::before {
      border-left: 100vw solid transparent;
      border-right: 50vw solid transparent;
      border-bottom: 8vw solid $blog-bg-inverse-color;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 3;
      content: '';
    }

    &.responsive-image {
      border-top-right-radius: $border-radius;
      border-top-left-radius: $border-radius;
    }
  }

  a {
    .blog-card-img {
      width: 100%;
      border-top-right-radius: $border-radius;
      border-top-left-radius: $border-radius;
      animation: fadein 0.5s;
    }
  }

  .blog-post-card-container {
    min-height: 134px;
  }
}

.blog-post-card.short-card {
  .blog-post-card-container {
    min-height: 80px;
  }

  .blog-card-title {
    margin: 0;
  }
}

.blog-post-card.pinned-card {
  .blog-card-img {
    width: 100%;
  }
}

.blog-card-title {
  @extend .h4;

  font-weight: 700;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 52px;
  line-height: 1.5rem;
  color: $text-black;
  transition: all 0.1s ease-in-out;
}

.blog-card-intro-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 0.875rem;
  color: $text-black;
  font-weight: 400;
}

@media #{$retina-screen} {
  .blog-card-intro-text {
    font-weight: inherit;
  }
}

.blog-card-date {
  font-size: 0.8125rem;
  color: $text-muted;
  display: flex;
  align-items: center;
  font-weight: 400;
  padding: 1rem;
  min-height: 36px;
}

@media #{$retina-screen} {
  .blog-card-date {
    font-weight: inherit;
  }
}
